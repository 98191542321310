import React, { Fragment, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { gist, cleanGist } from '../../actions/gist';
import { cleanFetch } from '../../actions/fetch';
import GistEditor from './GistEditor';
import MediumBtn from './../layout/Bits/MediumBtn';
import WarningModal from '../layout/Bits/WarningModal';
import MessageDisplay from '../layout/MessageDisplay';

import useDocumentTitle from '../layout/utils/useDocumentTitle';
import useBackground from '../layout/utils/useBackground';


const Gister = ({gistLoading, gist, cleanGist, cleanFetch}) => {
  
//   useBackground('#f8fafc', '#f8fafc');
  useBackground('#f1f5f9', '#f1f5f9');
  useDocumentTitle('Gist - Stedili')
  
  const [editorContentStr, setEditorContentStr] = useState('');
  const [warningModal,setWarningModal] = useState(false);
  const [warningData, setWarningData] = useState({
    warningMessage:'',
    warningTitle: ''
  });
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  const handleContentUpdate = (content) => {
    setEditorContentStr(content);
    setEditorData({...editorData, text: content});
  };

  const [editorData, setEditorData] = useState({
        text: "",
        title: "",
        url:"",
        description: "",
        gistDone: false
    });

  const { text, title, url, description, gistDone } = editorData;

  const closeWarningModal = () => {
    setWarningData({
      warningMessage:'',
      warningTitle: ''
    });
    setWarningModal(false);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const textLength = text.split(" ").length;
    const titleLength = title.split(" ").length;

    // console.log(text);
    if (textLength>100 && titleLength>2) {
      
      setMessage("Crunching text...");
      setShowMessage(true);
      gist(text, title, description, url);
      setEditorData({...editorData, gistDone:true});
    } else {
      if (textLength < 100){
        setWarningData({
          warningMessage: 'An article needs at least 100 words to create a useful summary. Try a different article.',
          warningTitle: 'Article is too short'
        });
        setWarningModal(true);
        return;
      } else {
        if(titleLength< 2) {
        setWarningData({
          warningMessage: 'Please add a meaningful title. It will help the summary!',
          warningTitle: 'Title is too short'
        });
        setWarningModal(true);
        return;
      }
      }
    }
    
   
  };


  useEffect (() =>{
    cleanGist();
    cleanFetch();
  },[cleanGist, cleanFetch]);

  // the gist action got called AND completed
  if (gistDone && !gistLoading) {
    return <Navigate to="/clipper" />;
  }

  return (
    <div className='main-container'>
        <div className="gist-panel-container with-right font-input " onSubmit={onSubmit}>
            <div className="gist-left-panel s-border">

                <div className='gist-panel-head'>1. Paste the article</div>
                <div className = "gist-editor" >
                    <GistEditor onUpdateContent={handleContentUpdate}/>
                </div>
            
            </div>
            <div className='form gist-right-panel s-border'>
                
                <div className='gist-panel-head'>2. Plug details</div>
                <div className='px-6 pb-8'>
                    <div className="form form-group">
                        <label className='font-quiet'>Article title</label>  
                        <input 
                            type="text" 
                            name="title" 
                            value = {title}
                            onChange={e => setEditorData({...editorData, title:e.target.value})}
                            className="url font-input" 
                            placeholder="" 
                            required
                            />
                    </div>
                    <div className="form form-group">
                        <label className='font-quiet'>Source link</label>    
                        <input 
                            label = "url"
                            type="url" 
                            name="url" 
                            value = {url}
                            onChange={e => setEditorData({...editorData, url:e.target.value})}
                            className="url font-input" 
                            placeholder="" 

                            />
                    </div>
                    <MediumBtn 
                        buttonFunction={onSubmit}
                        label='Get Key Points'
                        formatString={"btn-secondary my font-input"}
                    />
                </div>

      
        </div>
      </div>
      {warningModal && 
        <WarningModal
          onAccept={closeWarningModal}
          blurb={warningData.warningMessage}
          yesButtonText='Okay'
          modalTitle={warningData.warningTitle}
        />

      }
      
    </div>

)
};


const mapStateToProps = state => ({
    gistLoading: state.gist.loading
});


Gister.propTypes = {
gist: PropTypes.func.isRequired,
cleanGist: PropTypes.func.isRequired,
cleanFetch: PropTypes.func.isRequired,
gistLoading: PropTypes.bool.isRequired
}


export default connect(mapStateToProps,{gist, cleanGist, cleanFetch})(Gister);

