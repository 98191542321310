import React, {useState, useEffect, useCallback} from "react";
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { getCollectionBookmarks, updateCollection, clearSavedCollection } from '../../../actions/collection';
import {fetch, } from '../../../actions/fetch'

import useBackground from "../../layout/utils/useBackground";
import useDocumentTitle from "../../layout/utils/useDocumentTitle";
import BmPreview from "./BmPreview";
import DirectAdd from "./DirectAdd";
import { Bunch } from "../../layout/Icons";


const CollectionView = ({collection, savedCollection, fetchState, 
                        getCollectionBookmarks, updateCollection, clearSavedCollection, fetch}) => {

    useBackground('#f1f5f9', '#f1f5f9');
    // useBackground('#f8fafc', '#f8fafc');
    const location = useLocation();
    const [collectionInfo, setCollectionInfo] = useState({
        cId: location.state?.cId,
        cTitle: location.state?.cTitle,
        cUpdate: location.state?.outputDateString,
        dishId: location.state?.dishId,
        cSize: location.state?.cSize
    });

    const {cId, cTitle, cUpdate, dishId, cSize} = collectionInfo;
    useDocumentTitle(`${cTitle} collection - Stedili`);
    

    // This is stuff for adding bookmarks
    const [addMode, setAddMode] = useState(false);
    const [urlData, setUrlData] = useState({
        url: "",
        fetchDone: false
      });
    const {url, fetchDone} = urlData;
    const { content, fetchSuccess, loading } = fetchState;
    const [message, setMessage] = useState("");
    

    const dateFormatter = (dateStr) => {
         // Convert input string to a Date object
        const date = new Date(dateStr);

        // Extract day, month, and year components
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1; // Months are 0-indexed
        const year = date.getUTCFullYear();

        // Create the desired format
        const outputDateString = `${day}/${month}/${year}`;

        // Use toLocaleDateString with options for a more verbose response
        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        
        return outputDateString;
    }

    const switchAddMode = ()=> {
        setAddMode(!addMode);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        firstStage();
        };

    const firstStage = async () => {
        setMessage("Off to read the story...");

        await fetch(url);
        
        setUrlData({...urlData, fetchDone:true});

    }

    const secondStage = useCallback(() => {
        if (fetchDone) {
            setMessage("Crunching the text...");
            // console.log('entering second stage');
            const collectionData = {
                id: cId,
                title: content.article_title, 
                url: url,
                snippets: content.summary_sents,
                description: content.description ? content.description : ""
              };
            
            //This updates the savedCollection state, and then trigger the useEffect dependent on it
            
            updateCollection(collectionData); 
            
            setUrlData({
                url: "",
                fetchDone: false
              });
            
            // getCollectionBookmarks(dishId);
        }

    
      }, [fetchDone]);

    useEffect(() => {
        if(!loading && fetchSuccess){
            // setMessage("Saving the bookmark...");
            secondStage();
            
        };
        if(!loading && !fetchSuccess){
            setUrlData({
                url: "",
                fetchDone: false
              });
            // setMessage("");
        }
    }, [secondStage, loading]);

    // This takes care of bookmark updating in case of deletions (possibly also additions?)
    useEffect(()=> {
        if (savedCollection) {
            setCollectionInfo({
                ...collectionInfo,
                cSize: savedCollection.bookmarkCount,
                cUpdate: dateFormatter(savedCollection.updated)
            });
            setMessage("");
            }
        clearSavedCollection();
        getCollectionBookmarks(dishId);       
    },[savedCollection]);

    return (
        <div className='narrow_container'>
            
          <div className = "collection-container surround">

          {/* Collection info + button to add new link */}
            <div className="collection_info flex justify-between">
                <div className="collection_data">
                    {/* <div
                        className = "collection_title"
                    >
                        {cTitle}
                    </div> */}
                    <div className="pt-2">
                    <Bunch content = {cTitle} />
                    </div>
                    <div className="py-1">
                        <div className = "single-collection-size">
                            Containing <span className="single-collection-size-number"> {cSize}</span><span className="single-collection-size-item">{cSize === 1 ? ' item' : ' items'}</span>
                        </div>
                        <div className = "single-collection-size">
                            Last updated on <span className="single-collection-update">{cUpdate}</span>
                        </div>

                        
                    </div>
                </div>
            
                <div className="button_container px-1 flex items-center">
                    {!addMode && <button className="add-button" onClick={switchAddMode}>
                        <i className="fa-solid fa-circle-plus fa-xl"></i> {/* FontAwesome plus icon */}
                    </button>}
                    {addMode && <button className="add-button" onClick={switchAddMode}>
                        <i className="fa-solid fa-circle-minus fa-xl"></i> {/* FontAwesome plus icon */}
                    </button>}
                </div>
            </div>

            {/* Adding new bookmark box */}
            {addMode && 
                <DirectAdd 
                    urlData={urlData} 
                    setUrlData= {setUrlData} 
                    onSubmit={onSubmit} 
                    message={message} />
             }

            <div>
                    {collection?.map((bm, index) => (

                        <BmPreview 
                            key = {index} 
                            title = {bm.title} 
                            description = {bm.description}
                            savedString = {dateFormatter(bm.saved)}
                            bookmarkId = {bm._id}
                            collectionId = {cId}
                            /> 
                   
                    ))}
            </div>
          </div>  
        </div>
    )
}

const mapStateToProps = state => ({
    collection: state.collection.loadedCollection,
    fetchState: state.fetch,
    savedCollection: state.collection.savedCollection

  });

CollectionView.propTypes = {
    getCollectionBookmarks: PropTypes.func.isRequired,
    updateCollection: PropTypes.func.isRequired,
    clearSavedCollection: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    collection: PropTypes.array,
    fetchState: PropTypes.object.isRequired
};



export default connect(mapStateToProps, {getCollectionBookmarks, updateCollection, clearSavedCollection, fetch})(CollectionView);



// {/* <div className=" summary-sent-blue m p">
//                 <div className="md:flex md:justify-between"> 
//                     <input
//                         type="url"
//                         placeholder="Enter URL"
//                         value = {url}
//                         onChange={e => setUrlData({...urlData, url:e.target.value})} 
//                         className="font-input m p w-full surround"
//                     />
                    
//                     <div className="md:items-center m">
//                         <button className="font-input btn btn-primary md:w-auto" onClick={onSubmit}>
//                         Add
//                         </button>
//                     </div>
//                 </div>
//                 {/* {message} */}
//                 <MessageDisplay message="Boo" />
            
         
//             </div> */}