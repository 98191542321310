import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { clearScenarios, generateScenarioText } from '../../../../../../actions/angle';
import * as Icons from "../../Icons";
import { AngleM } from "../../../../../layout/Icons";
import MediumBtn from '../../../../../layout/Bits/MediumBtn';

import { useEditorContext } from '../../../../EditorContextProvider';

/* eslint-disable react-hooks/exhaustive-deps */

const ScenarioModal = ({dataObj, clearScenarios, generateScenarioText, scenarioState}) => {
  const {
    editor,
    closeAltsModal,
    signalTypes,
  } = useEditorContext();

  const {scenarioObj, paraIndex} = dataObj;
  const scenarioList = scenarioObj? [...scenarioObj.down, ...scenarioObj.up] : [];

  const [localScenarioObj, setLocalScenarioObj] = useState(scenarioObj? scenarioObj: null);
  const [localScenarioList, setLocalScenarioList] = useState(scenarioList)
  const [scenariosExist, setScenariosExist] = useState(scenarioList[0]? true: false);
  const [outputText, setOutputText] = useState(scenarioList[0]?scenarioList[0]:"Click button to generate connecting paragraphs");
  const [listPosition, setListPosition] = useState(0);

  const {scenarios, scenarioSuccess, loading } = scenarioState;
  const currentNode = editor.state.doc.content.content[paraIndex];
  const extractId = currentNode.attrs.extractId;
  const source = editor.storage.extractCache.extractObj[extractId].url;

  useEffect(()=> {

    if(scenarioSuccess && !loading && scenarios){
      const newScenarioObj = cloneDeep(scenarios);
      const scenarioList = [...newScenarioObj.down, ...newScenarioObj.up];

      setLocalScenarioList(scenarioList);
      setLocalScenarioObj(newScenarioObj);
      setOutputText(scenarioList[0]);
      setScenariosExist(true);
      clearScenarios();
    }
},[loading, scenarios]);
  
  useEffect (()=>{
    setOutputText(localScenarioList[listPosition]);
  }, [listPosition]);

  const clickForwards = () => {
    const currentIndex = listPosition
    setListPosition((currentIndex+1) % localScenarioList.length);
  }

  const clickBackwards = () => {
    const currentIndex = listPosition
    setOutputText(localScenarioList[(currentIndex + localScenarioList.length -1) % localScenarioList.length]);
    setListPosition((currentIndex + localScenarioList.length-1) % localScenarioList.length);
  }

  
  
  // const mockClickEffect = ()=>{
  //   console.log('Modal button clicked!');

  // };

  const closeThisModal = ()=>{
    const data = {
      packet: scenariosExist? localScenarioObj : null, 
      extractId: extractId,
      fieldType: signalTypes.scenarios
    };
    closeAltsModal(data);
  };

  const insertBelow = ()=>{
    const data = {
      packet: scenariosExist? localScenarioObj : null, 
      extractId: extractId,
      fieldType: signalTypes.scenarios
    };
    editor.commands.addParagraph(outputText, source, paraIndex+1, extractId);
    closeAltsModal(data);
  };

  const createOnSpot = ()=> {
    clearScenarios();
    //set something. currently choosing genParag.

    const text = editor.storage.extractCache.extractObj[extractId].genParag;

    generateScenarioText(extractId, text);
    //set something
  };

  return (
    <div className="relative z-10">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
       
       <div className="relative transform overflow-hidden rounded-md bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-4xl">
           <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
             <div className="mb-4">
             <AngleM content = "Idea connector" />
             <button className="modal-close" type="button" onClick={closeThisModal}>
                 <Icons.X />
             </button>
             </div>


             <label className="dropdown-label">Alternative</label>
             <div className='flex'>
              <div className='w-1/10 tools'>
                <button onClick = {clickBackwards} className='tool-btn'><i className="fa-solid fa-circle-arrow-left"></i></button>
              </div>
              <textarea
                  className="dropdown-item modal-textarea"
                  autoFocus
                  value={outputText}
                  readOnly
                  //  onChange = {(e) => {console.log("change");}}
                  rows = {2}
              />
              <div className='w-1/10 tools'>
                <button onClick = {clickForwards} className='tool-btn'><i className="fa-solid fa-circle-arrow-right"></i></button>
              </div>
              </div>
             </div>

             <div className="modal-btn-bar">
               <div className="modal-buttons my">
                  {!scenariosExist &&
                   <MediumBtn
                        buttonFunction={createOnSpot}
                        label='Generate New'
                        formatString={"button-approve font-input"}
                      />
                  }
                   {scenariosExist &&
                    <MediumBtn
                        buttonFunction={insertBelow}
                        label='Insert Below'
                        formatString={"button-approve font-input"}
                      />
                   }

                   
               </div>
           </div>
         </div>
     </div>
        
            
   </div>
 </div>
  );
}


const mapStateToProps = state => ({
    scenarioState: state.scenario,
});

ScenarioModal.propTypes = {
  dataObj:PropTypes.object.isRequired,
  clearScenarios: PropTypes.func.isRequired,
  generateScenarioText: PropTypes.func.isRequired,
}

export default connect(mapStateToProps,{clearScenarios, generateScenarioText})(ScenarioModal);