import React from 'react'
import PropTypes from 'prop-types'


const MediumBtn = ({buttonFunction, label, formatString, tablerIcon: TablerIcon = null }) => {



    return (
        <div className="flex items-center">
          <button type="button" onClick={buttonFunction} 
            className={`${formatString} btn-medium inline-flex items-center space-x-1.5`}>
            {TablerIcon && <TablerIcon size={16} />}
            <span >{label}</span>
          </button>
        </div>
      )

}


MediumBtn.propTypes = {
    buttonFunction: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    formatString: PropTypes.string.isRequired
};

export default MediumBtn;