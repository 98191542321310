import React, { useEffect } from "react";

// => Tiptap packages
import { useEditor, EditorContent } from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
// import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Code from "@tiptap/extension-code";
import History from "@tiptap/extension-history";

// import "./styles.css";

const GistEditor = ({onUpdateContent}) => {

    const content = "";
    const gistEditor = useEditor({
        extensions: [
        Document,
        History,
        Paragraph,
        Text,
        Bold,
        Underline,
        Italic,
        Strike,
        Code
        ],
        content
    });

    

    useEffect(() => {
        const getTextWithBreaks = () => {
            const textWithParaBreaks = gistEditor.state.doc.content.content.map(node => node.textContent).join('\n\n');
            return textWithParaBreaks;
          }
          
        if (gistEditor) {
            gistEditor.on('update', () => {
                const plainText = getTextWithBreaks();
                onUpdateContent(plainText);  // Send content to parent
            });
        }
    }, [gistEditor, onUpdateContent]);

    return (
        <EditorContent editor={gistEditor} />
    )

}

export default GistEditor;