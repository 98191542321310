import React, { useEffect, useState } from 'react';
// import { Link, Navigate } from 'react-router-dom';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CollectionItem from './CollectionItem';
import { Stack } from '../../layout/Icons';

import useBackground from '../../layout/utils/useBackground';
import useDocumentTitle from '../../layout/utils/useDocumentTitle';
import { getCollections } from '../../../actions/collection';


const Collections = ({storedCollectionList, getCollections}) => {

    useBackground('#f1f5f9', '#f1f5f9');
    useDocumentTitle('Collections - Stedili');
    // useBackground('#f3f4f6', '#f3f4f6');
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(()=> {
        // getCollections();
        // setLoaded(true); 
        const fetchCollections = async () => {
            try {
                await getCollections(); // Wait for getCollections to complete
                setLoaded(true);        // Set loaded to true only after data fetch
            } catch (err) {
                setError('Failed to load collections');  // Set error state if the request fails
            }
        };
        fetchCollections();
    }, [getCollections]);

    
    return (
        <div>

        <div className='narrow_container surround bg-white'>
            {/* <div className="my p">
                <p className="text-lg text-primary">Collections</p>
            </div> */}

            <div className="py-2">
                <Stack content={"Collections"} />
             </div>
            {loaded && storedCollectionList?.map((collection) => (
                <CollectionItem 
                    key={collection._id}
                    cId = {collection._id} 
                    cTitle = {collection.title} 
                    cUpdate = {collection.updated} 
                    cSize = {collection.bookmarkCount}
                    dishId = {collection.dishPointer}
                    />
            )) }
            
            
        </div>
        </div>   
    );

};

const mapStateToProps = state => ({
    storedCollectionList: state.collection.allCollections
  });

Collections.propTypes = {
    storedCollectionList: PropTypes.array,
    getCollections: PropTypes.func.isRequired  
};

export default connect(mapStateToProps,{getCollections})(Collections);