import React, {useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import {useEditorContext} from '../../Editor/EditorContextProvider';

// Import actions to manage the state of the editor on mount and unmount
import { clearLocalDraft } from '../../../actions/draft';

//Components
import CoreMenu from '../../Editor/TipTap/WorkingExample/Menus/CoreMenu';
import SmallBtn from '../../layout/Bits/SmallBtn';
import BinaryButton from '../../layout/Bits/BinaryButton';
import EditToggle from '../../Editor/EditToggle';
// import ButtonToggle from '../../layout/Bits/ButtonToggle';
import Dropdown from '../../layout/Dropdown';
import Docufy from '../../Editor/Docufy';

//Icons
import { 
  IconNews, 
  IconFile, 
  IconLayoutSidebarRightCollapse, 
  IconLayoutSidebarLeftCollapse,
} from '@tabler/icons-react';

const RibbonMenu = ({
  isRightPanelVisible, 
  visibleClick,
  loadedCollection,
  storedCollectionList, 
  selectedCollectionId, 
  handleCollectionChange,
  clearLocalDraft

}) => {

  const { 
    editor,
    setStartingList,
    // latestEditorState,
    // parsedState, 
    // setParsedState,
    editMode, 
    toggleEditMode,
    bulkDraft,
    toggleBold,
    toggleUnderline,
    toggleItalic,
    openLinkModal,
    } = useEditorContext();


   // This part sets up the mounting and unmounting, including local states.
  const populateStartingList = useCallback(() => {
    if (loadedCollection) {
        setStartingList(loadedCollection);
    }

  }, [loadedCollection, setStartingList]);

  useEffect(() => {
  populateStartingList();
  }, [populateStartingList]);    

  const newDraft = () => {
      clearLocalDraft();
      editor.commands.setContent("", true); //trigger an update
      editor.commands.clearDocSources();
      editor.commands.clearDocExtracts();
      }  

  return (
    <div className="ribbon-menu s-border">
      <div className='ribbon-container with-right'> 
        {/* Left Column */}
        <div className="ribbon-column">
          {/* Top Row */}
          <div className="ribbon-row">
            <div className='button-group-left'>
              <SmallBtn 
                buttonFunction={newDraft} 
                label='New draft' 
                formatString={"bg-white text-gray-700 border-solid border border-gray-400 hover:bg-purple-100"}
                tablerIcon = {IconFile}
              />
              <SmallBtn 
                buttonFunction={bulkDraft} 
                label='Newsletter' 
                formatString={"bg-white text-gray-700 border-solid border border-gray-400 hover:bg-purple-100"}
                tablerIcon = {IconNews}
              />

            </div>
            <div className='button-group-right'>
               
              {/* <ButtonToggle
                    onClick= {mockFunc}
                    tooltipText1= 'Drag and drop'
                    tooltipText2 = 'Editor'
                    tablerIcon1={IconCopy}
                    tablerIcon2={IconAlignBoxLeftTop}
                  /> */}
            </div>
          </div>
          {/* Bottom Row */}
          <div className="ribbon-row">
            {/* Additional content/buttons for the bottom row of the left column */}
            <div className='button-group-left'>
            {editor && <Docufy editor = {editor}  />}  
            {/* <SmallBtn 
                buttonFunction={mockFunc} 
                label='Download' 
                formatString={"bg-purple-600 text-white border-solid border border-gray-400 hover:bg-purple-100 hover:text-gray-700"}
                tablerIcon = {IconFileDownload}
              /> */}
            </div>
            <div className='button-group-right'>

            {editMode && <CoreMenu 
              editor={editor}
              openModal={openLinkModal}
              toggleBold={toggleBold}
              toggleUnderline={toggleUnderline}
              toggleItalic={toggleItalic}
            />}
            <EditToggle editMode={editMode} modeToggle={toggleEditMode}/>
            
                

              
             
            </div>

          </div>
        </div>
        
        {/* Right Column */}
        <div className="ribbon-column">
          {/* Top Row */}
          <div className="ribbon-row">
            {/* Content/buttons for the top row of the right column */}
            <div className='button-group-left'>
              <div >
                <label className="dropdown-label">Select a collection:</label>
              </div>
            </div>
            <div className='button-group-right'>
              <BinaryButton 
                  onClick= {visibleClick}
                  tooltipText= {isRightPanelVisible ? 'Hide' : 'Show'}
                  tablerIcon1={IconLayoutSidebarLeftCollapse}
                  tablerIcon2={IconLayoutSidebarRightCollapse}
                />
            </div>
          </div>
          {/* Bottom Row */}
          <div className="ribbon-row">
            {/* Additional content/buttons for the bottom row of the right column */}
            <Dropdown 
              items={storedCollectionList}
              selectedItem = {selectedCollectionId} 
              onItemChange = {handleCollectionChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
    
}

const mapStateToProps = state => ({
  // isAuthenticated: state.auth.isAuthenticated,
  loadedCollection: state.collection.loadedCollection,
  });

RibbonMenu.propTypes = {
    isRightPanelVisible: PropTypes.bool.isRequired,
    visibleClick: PropTypes.func.isRequired,
    loadedCollection: PropTypes.array,
    storedCollectionList: PropTypes.array.isRequired, 
    selectedCollectionId: PropTypes.string.isRequired,
    handleCollectionChange: PropTypes.func.isRequired,
    clearLocalDraft: PropTypes.func.isRequired
}

export default connect(mapStateToProps,{clearLocalDraft})(RibbonMenu);
