import React from "react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';



const CollectionItem = ({cId, cTitle, cUpdate, cSize, dishId}) => {

    // Convert input string to a Date object
    const date = new Date(cUpdate);

    // Extract day, month, and year components
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are 0-indexed
    const year = date.getUTCFullYear();

    // Create the desired format
    const formattedDate = `${day}/${month}/${year}`;

            // Use toLocaleDateString with options for a more verbose response
    const outputDateString= date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    
    // const [clicked, setClicked] = useState(false);

    const navigate = useNavigate();

    const onClick = () => {
        navigate('/collectionview', {state: {cId, cTitle, outputDateString, cSize, dishId}});
    }

    return (
        <div >
          <div className = "collection-container surround ">
            <div 
                className = "collection-title"
                onClick={onClick}>
                {cTitle}
            </div>
            <hr className="border-t-1 border-gray-300 mt-1 mb-3"></hr>
            <div className = "collection-size">
               <span className="collection-size-number"> {cSize}</span><span className="collection-size-item">{cSize === 1 ? ' item' : ' items'}</span>
            </div>
            <div className = "collection-size">
                Last updated on <span className="collection-update">{outputDateString}</span>
            </div>
          </div>  
        </div>
    )
}

CollectionItem.propTypes = {
    cId: PropTypes.string.isRequired,
    cTitle: PropTypes.string,
    cUpdate: PropTypes.string,
    cSize: PropTypes.number,
    dishId: PropTypes.string

};

export default CollectionItem;