import React from 'react'
import PropTypes from 'prop-types'

import MessageDisplay from '../../layout/MessageDisplay'
import MediumBtn from '../../layout/Bits/MediumBtn'

const DirectAdd = ({urlData, setUrlData, onSubmit, message}) => {
  return (
    <div className="mx-2 mb-4 overflow-hidden bg-white border border-gray-200 rounded-md text-left shadow-md sm:mt-6">
    {/* sm:my-2 sm:w-full  */}
    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
    {/* <div className=" summary-sent-blue m p"> */}
    <label className="dropdown-label">Paste an article link:</label>
        <div className="md:flex md:justify-between"> 
            <input
                type="url"
                placeholder="Enter URL"
                value = {urlData.url}
                onChange={e => setUrlData({...urlData, url:e.target.value})} 
                className="font-input m p w-full surround"
            />
        </div>
    </div>
    <div className="bg-slate-100 pb-5 pt-5 sm:flex sm:justify-between sm:flex-row-reverse sm:px-6">
        <MediumBtn
            buttonFunction={onSubmit}
            label='Add Article'
            formatString={"btn-primary font-input"}    
        />

    <MessageDisplay message={message} />
    </div>

    {/* {message} */}
</div>
// </div>
  )
}

DirectAdd.propTypes = {
    urlData: PropTypes.object.isRequired,
    setUrlData: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
}

export default DirectAdd