import { Link, Angle, Bolt } from "../Editor/TipTap/WorkingExample/Icons";

export const Eye = ({content}) => (
    <div className="sm:flex sm:items-center"> 
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sunset sm:mx-0 sm:h-8 sm:w-8">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-white w-5 h-5">
            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
            <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
            </svg>
        </div>
        <div className="mt-4 text-left sm:ml-2 sm:mt-0 sm:text-left">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">{content}</h1>
        </div>      
    </div>   

);

export const Get = ({content}) => (
    <div className="sm:flex sm:items-center">        
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sunset sm:mx-0 sm:h-8 sm:w-8">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-white">
             <path d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z" />
             <path fillRule="evenodd" d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zM12 10.5a.75.75 0 01.75.75v4.94l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72v-4.94a.75.75 0 01.75-.75z" clipRule="evenodd" />
        </svg>

        </div>
        <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
            <div className="fetch-title" >{content}</div>
        </div>      
    </div>

);

export const Stack = ({content}) => (
    <div className="sm:flex sm:items-center">        
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sunset sm:mx-0 sm:h-8 sm:w-8">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
            </svg>
        </div>
        <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
            <h1 className="section-title" >{content}</h1>
        </div>      
    </div>

);

export const Bunch = ({content}) => (
    <div className="sm:flex sm:items-center">        
        <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full sunset sm:mx-0 sm:h-8 sm:w-8">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
        </svg>


        </div>
        <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
            <h1 className="section-title" >{content}</h1>
        </div>      
    </div>

);

export const LinkM = ({content}) => (
    <div className="sm:flex sm:items-center">        
    <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gradient-to-br from-emerald-500 to-emerald-300 sm:mx-0 sm:h-8 sm:w-8">
        <Link color = "white" className="w-6 h-6 text-white"/>
    </div>
    <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
        <h1 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">{content}</h1>
    </div>      
</div>
)

export const AngleM = ({content}) => (
    <div className="sm:flex sm:items-center">        
    <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gradient-to-br from-emerald-500 to-emerald-300 sm:mx-0 sm:h-8 sm:w-8">
        <Angle color = "white" className="w-6 h-6 text-white"/>
    </div>
    <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
        <h1 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">{content}</h1>
    </div>      
</div>
)

export const BoltM = ({content}) => (
    <div className="sm:flex sm:items-center">        
    <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gradient-to-br from-emerald-500 to-emerald-300 sm:mx-0 sm:h-8 sm:w-8">
        <Bolt color = "white" className="w-6 h-6 text-white"/>
    </div>
    <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
        <h1 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">{content}</h1>
    </div>      
</div>
)

export const Bell = ({content}) => (
    <div className="sm:flex sm:items-center">        
    <div className="mx-auto flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-gradient-to-br from-emerald-500 to-emerald-300 sm:mx-0 sm:h-8 sm:w-8">
        <svg fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5 text-white">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
        </svg>
    </div>
    <div className="mt-4 text-center sm:ml-2 sm:mt-0 sm:text-left">
        <h1 className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">{content}</h1>
    </div>      
</div>
)

export const Click = () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24" 
      fill="black" 
      className="w-5 h-5"
      >
      <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75V4.5a.75.75 0 01-1.5 0V2.25A.75.75 0 0112 1.5zM5.636 4.136a.75.75 0 011.06 0l1.592 1.591a.75.75 0 01-1.061 1.06l-1.591-1.59a.75.75 0 010-1.061zm12.728 0a.75.75 0 010 1.06l-1.591 1.592a.75.75 0 01-1.06-1.061l1.59-1.591a.75.75 0 011.061 0zm-6.816 4.496a.75.75 0 01.82.311l5.228 7.917a.75.75 0 01-.777 1.148l-2.097-.43 1.045 3.9a.75.75 0 01-1.45.388l-1.044-3.899-1.601 1.42a.75.75 0 01-1.247-.606l.569-9.47a.75.75 0 01.554-.68zM3 10.5a.75.75 0 01.75-.75H6a.75.75 0 010 1.5H3.75A.75.75 0 013 10.5zm14.25 0a.75.75 0 01.75-.75h2.25a.75.75 0 010 1.5H18a.75.75 0 01-.75-.75zm-8.962 3.712a.75.75 0 010 1.061l-1.591 1.591a.75.75 0 11-1.061-1.06l1.591-1.592a.75.75 0 011.06 0z" clipRule="evenodd" />
    </svg> 
  )
