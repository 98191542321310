import React, {useState, useEffect}  from 'react'

//Components
import SmallBtn from '../../layout/Bits/SmallBtn';
import { Eye, Click } from '../../layout/Icons';

import CollectionAdd from './CollectionAdd';

//Icons
import { 
  IconNews, 
  IconFile, 
  IconLayoutSidebarRightCollapse, 
  IconLayoutSidebarLeftCollapse,
} from '@tabler/icons-react';

const TopRibbon = ({articleData, handleAddSnippet, buttonBG}) => {
    
    const {url, title, description, titleSnippet, snippets} = articleData;
    

  return (
    <div className="ribbon-menu s-border">
      <div className='ribbon-container with-right'> 
        {/* Left Column */}
        <div className="ribbon-column">
          {/* Top Row */}
          <div className="ribbon-row">
            <div className='button-group-left'>
              <div className = 'ribbon-title'>Clipper</div>
            </div>
            <div className='button-group-right'>
               

            </div>
          </div>
          {/* Bottom Row */}
          <div className="ribbon-row">
            {/* Additional content/buttons for the bottom row of the left column */}
            <div className='button-group-left'>
                <div >
                    <label className="help-label">1. Highlight and grab the paragraphs you want to keep</label>
                </div>
            </div>
            <div className='button-group-right'>
            
                    <div className='flex items-center'>
                        <button 
                            onClick = {handleAddSnippet} 
                            className={`${buttonBG} btn-small inline-flex items-center space-x-1.5 text-gray-700 border-solid border border-gray-400 hover:bg-purple-100`}
                        >
                            <Click />
                            <span>Grab highlight</span>
                        </button>
                    </div>
                
           
            </div>
          </div>
        </div>
        
        {/* Right Column */}
        <div className="ribbon-column">
          {/* Top Row */}
          <div className="ribbon-row">
            {/* Content/buttons for the top row of the right column */}
            <div className='button-group-left'>

            </div>
            <div className='button-group-right'>

            </div>
          </div>
          {/* Bottom Row */}
          <div className="ribbon-row">
            {/* Additional content/buttons for the bottom row of the right column */}
            <div className='button-group-left'>
                <div >
                    <label className="help-label">2. Change order with drags and drops </label>
                </div>
            </div>
            <div className='button-group-right'>
                <CollectionAdd urlTitle = {title} description = {description} snippets = {[titleSnippet,...snippets]} url = {url}/>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
    
}



TopRibbon.propTypes = {

}

export default TopRibbon;
